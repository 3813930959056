import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import SoftBadge from 'components/common/SoftBadge';
import axiosInstance from 'http/axiosConfig';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const handlePurchase = async event => {
  console.log(event);
  const packName = event.target.id;
  try {
    const response = await axiosInstance.post('/create-checkout-session/', {
      packName
    });
    console.log(response);
    window.location.replace(response.data.redirectURL);
    // Manejar la respuesta del backend, como mostrar un mensaje de éxito o redirigir a una página de pago
  } catch (error) {
    // Manejar cualquier error que pueda ocurrir al hacer la llamada a la API
    console.error('Error while making the purchase:', error);
    // Mostrar una notificación de error al usuario
    toast.error('Error while making the purchase. Please try again later.');
  }
};

const userData = JSON.parse(localStorage.getItem('userData'));
let userPlan = userData ? userData.plan : '';
let loadHistoricalDataPurchase = userData
  ? userData.loadHistoricalDataPurchase
  : '';

let daysLeftInFreeTrial = undefined;
if (userPlan === 'free-trial') {
  userPlan = 'base_pack';
  daysLeftInFreeTrial = userData ? userData.daysLeftInFreeTrial : '';
}

const PricingDefaultCard = ({
  pricing: {
    title,
    name,
    subTitle,
    price,
    purchaseType,
    url,
    buttonText,
    isFeatured,
    featureTitle,
    features
  },
  isPurchasable
}) => {
  return (
    <Col
      lg={3}
      className={classNames('border-top border-bottom', 'pricing-cards', {
        'dark__bg-1000 px-4 px-lg-0': isFeatured
      })}
      style={{ backgroundColor: isFeatured && 'rgba(115, 255, 236, 0.18)' }}
    >
      <div className="h100 d-flex flex-column align-items-center justify-content-center">
        {name === userPlan ? (
          <Badge bg="success" className="mb-1 pricing-card-selected-plan-badge">
            Your plan
            {daysLeftInFreeTrial !== undefined && (
              <span className="ms-2">
                ({daysLeftInFreeTrial} days of free trial remaining)
              </span>
            )}
          </Badge>
        ) : (
          <div className="mb-1" style={{ height: '1.5rem' }}></div> // Placeholder space
        )}

        <div className="text-center p-4">
          <h3 className="fw-normal my-0 title-pricing-card">{title}</h3>
          <p className="mt-3 subtitle-pricing-card">{subTitle}</p>

          <h2 className="fw-medium my-4">
            <sup className="fw-normal fs-2 me-1">$</sup>
            {price}
            {purchaseType === 'recurring' && (
              <small className="fs--1 text-700">/ month</small>
            )}
          </h2>

          {isPurchasable ? (
            loadHistoricalDataPurchase != null && name === 'load_data_pack' ? (
              <Button
                as={Link}
                variant={isFeatured ? 'primary' : 'outline-primary'}
                to={url}
                id={name}
                onClick={handlePurchase}
              >
                Purchase another dataset
              </Button>
            ) : (
              <Button
                as={Link}
                variant={isFeatured ? 'primary' : 'outline-primary'}
                to={url}
                id={name}
                onClick={handlePurchase}
              >
                {name === userPlan ? 'Manage my subscription' : buttonText}
              </Button>
            )
          ) : (
            <Button
              variant="outline-primary"
              href="https://github.com/marketplace/gitlights-analytics"
              target="_blank"
            >
              Get Started Free Trial!
            </Button>
          )}
        </div>
        <hr className="border-bottom-0 m-0" />
        <div className="text-start px-sm-4 py-4">
          <h5 className="fw-medium fs-0">{featureTitle}</h5>
          <ul className="list-unstyled mt-3">
            {features.map(feature => (
              <li className="py-1" key={feature.id}>
                {feature.blocked ? (
                  <FontAwesomeIcon icon="ban" className="me-2 text-error" />
                ) : (
                  <FontAwesomeIcon icon="check" className="me-2 text-success" />
                )}{' '}
                {feature.title}{' '}
                {feature.tag && (
                  <SoftBadge pill bg={feature.tag.type}>
                    {feature.tag.label}
                  </SoftBadge>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Col>
  );
};

PricingDefaultCard.propTypes = {
  pricing: PropTypes.shape({
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    purchaseType: PropTypes.string.isRequired,
    url: PropTypes.string,
    buttonText: PropTypes.string,
    isFeatured: PropTypes.bool,
    featureTitle: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        tag: PropTypes.shape({
          label: PropTypes.string,
          type: PropTypes.string
        })
      })
    )
  }),
  isPurchasable: PropTypes.bool
};
PricingDefaultCard.defaultProps = {
  isPurchasable: true
};
export default PricingDefaultCard;
