import axios from 'axios';
import { logout } from 'components/common/logout';
import { toast } from 'react-toastify';
import { getOrgFromPath, isPublicDomain } from 'http/domainUtils';
// Create an instance of Axios with custom configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST // Your API base URL
});

const org = getOrgFromPath();

// Add an interceptor to the instance to set the token
axiosInstance.interceptors.request.use(config => {
  // Retrieve the token from where you store it (e.g., localStorage, Redux state)
  const token = localStorage.getItem('accessToken'); // Replace with your method to get the token

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.org = '';
  if (isPublicDomain() && org !== undefined) {
    config.headers.org = org;
  }
  return config;
});

// Add an interceptor to handle response errors, e.g., 401 (Unauthorized)
axiosInstance.interceptors.response.use(
  response => {
    // If the response status is OK (2xx), return the response as is
    if (response.status === 200 && response.data.toast_message) {
      toast.success(response.data.toast_message, {
        position: 'bottom-right'
      });
    }
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized response here
      if (error.response.data == 'AppNotInstalled') {
        logout();
        window.location.replace(
          'https://github.com/marketplace/gitlights-analytics'
        );
      } else if (error.response.data == 'OutOfFreeTrial') {
        window.location.replace('/plan?plan=out-of-free-trial');
      } else {
        logout();
      }
      // You can also use React Router for navigation if your app uses it
      // history.push('/'); // Example if using React Router
    } else if (error.response && error.response.status === 402) {
      toast.warning(
        'This request is not available with the current plan. Go to the plans section for more information.',
        { position: 'bottom-right' }
      );
    } else if (error.response && error.response.status === 403) {
      if (error.response.data.toast_message) {
        toast.warning(error.response.data.toast_message, {
          position: 'bottom-right'
        });
      } else {
        toast.warning(
          'You are not authorized to perform this action. Please contact support if you believe this is an error.',
          { position: 'bottom-right' }
        );
      }
    } else if (error.response && error.response.status === 404) {
      window.location.replace('/errors/404');
    } else if (
      error.response.status === 500 &&
      error.response.data.toast_message
    ) {
      toast.error(error.response.data.toast_message, {
        position: 'bottom-right'
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
