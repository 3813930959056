import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { UserDataProvider } from 'userContext/UserDataContext';

const container = document.getElementById('main');

// Check if the container already has pre-rendered content
if (container.hasChildNodes()) {
  hydrateRoot(
    container,
    <React.StrictMode>
      <UserDataProvider>
        <Main>
          <App />
        </Main>
      </UserDataProvider>
    </React.StrictMode>
  );
} else {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <UserDataProvider>
        <Main>
          <App />
        </Main>
      </UserDataProvider>
    </React.StrictMode>
  );
}
