import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import is from 'is_js';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

import MainRoutes from 'routes';
import LoginPage from './loginPage';
import { isPublicDomain } from 'http/domainUtils';
import AppContext from 'context/Context';
import { CloseButton } from 'components/common/Toast';

// Filtros y providers
import DashboardFiltersProvider from 'components/dashboards/DashboardFiltersProvider';
import DashboardFilterDevelopersProvider from 'components/dashboards/DashboardFilterDevelopersProvider';
import DashboardFilterRepositoriesProvider from 'components/dashboards/DashboardFilterRepositoriesProvider';

////////////////////////////////////////////////////////////////////////////////
// Función auxiliar para comprobar si hay token en la URL o localStorage
const checkIsLogged = () => {
  if (window.location.search.includes('token=')) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    localStorage.setItem('accessToken', token);
    return true;
  }
  return localStorage.getItem('accessToken') != null;
};

////////////////////////////////////////////////////////////////////////////////

const App = () => {
  // --- Detección de landing ---
  const landing = window.location.pathname === '/';
  const [isLanding] = useState(landing);

  // --- Detección de login ---
  const isLogged = checkIsLogged();
  const [isLogin] = useState(isLogged);

  // --- Config y clases en <html> (para estilos condicionales) ---
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  // --- Efecto para añadir clases según el navegador/SO ---
  useEffect(() => {
    if (is.windows()) HTMLClassList.add('windows');
    if (is.chrome()) HTMLClassList.add('chrome');
    if (is.firefox()) HTMLClassList.add('firefox');
    if (is.safari()) HTMLClassList.add('safari');
  }, [HTMLClassList]);

  // --- Efecto para layout "double-top" ---
  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition, HTMLClassList]);

  // --- Fechas por defecto (90 días atrás y hoy) ---
  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

  const dateStart =
    localStorage.getItem('filterStartDate') !== null
      ? new Date(localStorage.getItem('filterStartDate'))
      : ninetyDaysAgo;

  const dateEnd =
    localStorage.getItem('filterEndDate') !== null
      ? new Date(localStorage.getItem('filterEndDate'))
      : new Date();

  const formattedStartDate =
    dateStart.getDate() +
    '/' +
    (dateStart.getMonth() + 1) +
    '/' +
    dateStart.getFullYear();

  const formattedEndDate =
    dateEnd.getDate() +
    '/' +
    (dateEnd.getMonth() + 1) +
    '/' +
    dateEnd.getFullYear();

  // --- Estado de los filtros globales ---
  const [filters, setFilters] = useState({
    id: 50,
    startDate: formattedStartDate,
    endDate: formattedEndDate
  });
  const filtersValue = { filters, setFilters };

  // --- Estado para filtro de developers ---
  const [developersFilter, setDevelopersFilter] = useState({
    developers: 'developers'
  });
  const developersFilterValue = { developersFilter, setDevelopersFilter };

  // --- Estado para filtro de repos ---
  const [repositoriesFilter, setRepositoriesFilter] = useState({
    repositories: 'repositories'
  });
  const repositoriesFilterValue = { repositoriesFilter, setRepositoriesFilter };

  // --- Detectar dominio público ---
  const isPublic = isPublicDomain();

  // --- Router principal con ToastContainer ---
  const mainRouter = (
    <Router basename={process.env.PUBLIC_URL}>
      <MainRoutes />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeButton={CloseButton}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Router>
  );

  // --- Render final ---
  // Lógica:
  //  - Si no es dominio público y no estamos en la landing, comprobamos login.
  //  - Si no hay login => LoginPage.
  //  - Si hay login => renderizamos providers + rutas.
  //  - Si es dominio público => directamente providers + rutas.
  const renderDashboard = () => {
    return (
      <DashboardFiltersProvider value={filtersValue}>
        <DashboardFilterDevelopersProvider value={developersFilterValue}>
          <DashboardFilterRepositoriesProvider value={repositoriesFilterValue}>
            {mainRouter}
          </DashboardFilterRepositoriesProvider>
        </DashboardFilterDevelopersProvider>
      </DashboardFiltersProvider>
    );
  };

  return (
    <>
      {!isPublic ? (
        // Dominio privado
        <>
          {!isLanding ? (
            // Si NO estamos en la landing
            <>{!isLogin ? <LoginPage /> : renderDashboard()}</>
          ) : (
            // Si es la landing
            <>{mainRouter}</>
          )}
        </>
      ) : (
        renderDashboard()
      )}
    </>
  );
};

export default App;
