import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Spinner, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosInstance from 'http/axiosConfig';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import { useUserData } from 'userContext/UserDataContext';

const PublicAccountSettings = () => {
  const [isPublic, setIsPublic] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Start in loading state
  const [showPublicModal, setShowPublicModal] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const { userData } = useUserData();

  // Fetch the current public status from the backend on component mount
  useEffect(() => {
    const fetchPublicStatus = async () => {
      try {
        const response = await axiosInstance.get('/get-public-status/');
        // The backend returns { public_status: boolean }
        setIsPublic(response.data.public_status);
      } catch (error) {
        console.error('Error fetching public status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPublicStatus();
  }, []);

  // Handle the switch change
  const handleSwitchChange = () => {
    // If toggling from private => public => show the modal
    if (!isPublic) {
      setShowPublicModal(true);
    } else {
      // If toggling from public => private => do it immediately
      updatePublicStatus(false);
    }
  };

  // Function to update the public status in the backend
  const updatePublicStatus = async newStatus => {
    setShowPublicModal(false);
    setIsLoading(true);

    try {
      const response = await axiosInstance.post('/update-public-status/', {
        makePublic: newStatus
      });
      // The backend returns { public_status: boolean }
      setIsPublic(response.data.public_status);
    } catch (error) {
      console.error('Error updating public status:', error);
    } finally {
      setIsLoading(false);
      setConfirmationText('');
    }
  };

  // URL to display when the account is public
  const publicUrl = 'https://public.gitlights.com/' + userData.organization; // adjust as needed

  return (
    <>
      <WidgetSectionTitle
        icon="user"
        title="Public Account Settings"
        subtitle="Manage the public visibility of your Gitlights account"
        transform="shrink-4"
        className="mb-4 mt-2"
      />

      {/* Bloque de texto introductorio con mayor distinción visual */}
      <div className="border rounded bg-light p-4 mb-4">
        <p>
          Showcase your organization’s work to the world by enabling the{' '}
          <strong>"Share public repositories"</strong> option!
        </p>
        <p>
          This feature allows you to activate a public URL where anyone can
          explore the activity of your <strong>public repositories</strong>,
          highlighting your team's contributions and impact. Visitors will have
          access to a dedicated view of your <strong>dashboard menu</strong>,
          making it easier than ever to share insights and foster collaboration
          with the broader community.
        </p>
        <p>
          <span role="img" aria-label="lock">
            🔒
          </span>{' '}
          <strong>Your private data is safe</strong>:<br />
          We value your privacy.{' '}
          <strong>Private repository data will NEVER be shared</strong>, even
          with this option enabled. Only the information from your{' '}
          <strong>public repositories</strong> will be displayed. Screens such
          as <strong>settings</strong>, <strong>plans</strong>, and others will
          remain completely restricted.
        </p>
        <p>
          Promote transparency, attract contributors, and showcase your work
          with confidence while keeping your private data fully protected!
        </p>
      </div>

      <div className="border rounded bg-light p-4 mb-4">
        <Row className="align-items-center g-3">
          <Col xs="auto">
            <Form.Label className="form-switch-label">
              <h5>
                <FontAwesomeIcon
                  icon={isPublic ? 'toggle-on' : 'toggle-off'}
                  className="fs-0 theme-control-toggle-label"
                />{' '}
                Share public repositories
              </h5>
            </Form.Label>
          </Col>
          <Col>
            {isLoading ? (
              <Spinner animation="border" role="status" />
            ) : (
              <Form.Check
                type="switch"
                id="publicAccountSwitch"
                checked={isPublic}
                onChange={handleSwitchChange}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <label className="form-label">
              <strong>Your public URL:</strong>
            </label>
            <div>
              {isPublic ? (
                <a href={publicUrl} target="_blank" rel="noopener noreferrer">
                  {publicUrl}
                </a>
              ) : (
                <span className="text-muted">{publicUrl} (Disabled)</span>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Modal show={showPublicModal} onHide={() => setShowPublicModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Public Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to enable{' '}
            <strong>"Share public repoitories"</strong>? Please type{' '}
            <strong>share public</strong> below to confirm.
          </p>
          <Form.Control
            type="text"
            placeholder="Type share public"
            value={confirmationText}
            onChange={e => setConfirmationText(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPublicModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={confirmationText.trim().toLowerCase() !== 'share public'}
            onClick={() => updatePublicStatus(true)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublicAccountSettings;
