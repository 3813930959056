import Account from 'components/account-settings/account';
import Settings from 'components/account-settings/settings';
import WeeklyReportsView from 'components/reports/weeklyReports';
import CommitsView from 'components/dashboards/commits';
import Dashboard from 'components/dashboards/default';
import DeveloperDetailView from 'components/dashboards/developers/detail';
import DevelopersView from 'components/dashboards/developers/global';
import InvestmentBalance from 'components/dashboards/investment-balance';
import PullRequestsView from 'components/dashboards/prs';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Landing from 'components/pages/landing/Landing';
import LoadHistoricalPage from 'components/pages/loading-data/LoadHistoricalPage';
import SubscriptionPurchaseSuccess from 'components/pages/loading-data/SubscriptionPurchaseSuccess';
import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault';
import SupportPage from 'components/pages/support/supportPage';
import FaqsPage from 'components/pages/faqs/faqsPage';
import React from 'react';
import { isPublicDomain, getOrgFromPath } from 'http/domainUtils';
import PublicAccountSettings from 'components/make-public/makePublic';

import {
  Navigate,
  useNavigate,
  useLocation,
  useSearchParams,
  Route,
  Routes
} from 'react-router-dom';
import ErrorLayout from '../layouts/ErrorLayout';
import MainLayout from '../layouts/MainLayout';

// ECHARTS CONFIG
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { RadarChart } from 'echarts/charts';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  RadarChart,
  LegendComponent,
  DatasetComponent
]);
//

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="landing" element={<Landing />} />
      <Route path="/" element={<Landing />} />
      <Route path="/load-historical" element={<LoadHistoricalPage />} />
      <Route
        path="/subscription-purchase-success"
        element={<SubscriptionPurchaseSuccess />}
      />
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      {/* //--- MainLayout Starts  */}

      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route path="/dashboard/main" element={<Dashboard />} />
        <Route path="dashboard/commits" element={<CommitsView />} />
        <Route path="dashboard/prs" element={<PullRequestsView />} />
        <Route
          path="dashboard/investment-balance"
          element={<InvestmentBalance />}
        />
        <Route path="dashboard/developers" element={<DevelopersView />} />
        <Route path="dashboard/developer" element={<DeveloperDetailView />} />
        <Route path="plan" element={<PricingDefault />} />
        <Route path="account" element={<Account />} />
        <Route path="settings" element={<Settings />} />
        <Route path="make-public" element={<PublicAccountSettings />} />
        <Route
          path="reports/week"
          element={<WeeklyReportsView period="week" />}
        />
        <Route
          path="reports/month"
          element={<WeeklyReportsView period="month" />}
        />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/faq" element={<FaqsPage />} />
      </Route>

      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

const GenericRedirectToOrg = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const orgStored = localStorage.getItem('gitlights_org');
    if (orgStored) {
      const queryString = searchParams.toString()
        ? `?${searchParams.toString()}`
        : '';
      const newPath = `/${orgStored}${location.pathname}`;

      navigate(`${newPath}${queryString}`, { replace: true });
    } else {
      navigate('errors/404', { replace: true });
    }
  }, [navigate, location, searchParams]);

  // Este componente no renderiza nada, solo redirige.
  return null;
};
const RedirectToOrgDashboard = () => {
  //const { org } = useParams();
  const org = getOrgFromPath();
  //alert('redirect-> ' + org);
  if (!org) {
    const orgStored = localStorage.getItem('gitlights_org');
    if (orgStored) {
      return <Navigate to={`/${orgStored}/dashboard/main`} replace />;
    } else {
      return <Navigate to={'errors/404'} replace />;
    }
  }

  return <Navigate to={`/${org}/dashboard/main`} replace />;
};

const PublicRoutes = () => {
  //const { org } = useParams();
  const org = getOrgFromPath();
  //alert('main-> ' + org);
  if (org !== undefined) {
    localStorage.setItem('gitlights_org', org);
  }
  return (
    <Routes>
      <Route path="/" element={<RedirectToOrgDashboard />} />

      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      {/* //--- MainLayout Starts  */}

      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route path="/dashboard/main" element={<GenericRedirectToOrg />} />
        <Route path="/dashboard/developer" element={<GenericRedirectToOrg />} />
        <Route
          path="/dashboard/developers"
          element={<GenericRedirectToOrg />}
        />
        <Route path="dashboard/commits" element={<GenericRedirectToOrg />} />
        <Route path="dashboard/prs" element={<GenericRedirectToOrg />} />
        <Route
          path="dashboard/investment-balance"
          element={<GenericRedirectToOrg />}
        />
        {/*Dashboard with ORG*/}
        <Route path="/:org" element={<RedirectToOrgDashboard />} />
        <Route path="/:org/dashboard/main" element={<Dashboard />} />
        <Route path="/:org/dashboard/commits" element={<CommitsView />} />
        <Route path="/:org/dashboard/prs" element={<PullRequestsView />} />
        <Route
          path="/:org/dashboard/investment-balance"
          element={<InvestmentBalance />}
        />

        <Route path="/:org/dashboard/developers" element={<DevelopersView />} />
        <Route
          path="/:org/dashboard/developer"
          element={<DeveloperDetailView />}
        />
        {/*FAQS and Support*/}
        <Route path="/support" element={<SupportPage />} />
        <Route path="/faq" element={<FaqsPage />} />
      </Route>

      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

const MainRoutes = () => {
  return isPublicDomain() ? <PublicRoutes /> : <PrivateRoutes />;
};

export default MainRoutes;
