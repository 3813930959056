import React, { useContext, useEffect, useState, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';

import axiosInstance from 'http/axiosConfig';
import { isPublicDomain } from 'http/domainUtils';
import AppContext from 'context/Context';

const MainLayout = () => {
  const location = useLocation();
  const isKanban = location.pathname.includes('kanban');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  // Estados para controlar la llamada a get_init_app
  const [loading, setLoading] = useState(true);
  const [setIsInitData] = useState(false);

  const hasFetchedRef = useRef(false);

  // Llamada a get_init_app sólo al montar este layout
  useEffect(() => {
    // Si es dominio privado, necesitamos comprobar si hay token
    const isPublic = isPublicDomain();
    const token = localStorage.getItem('accessToken');

    if (!isPublic && !token) {
      //FIXME Redirect to Login Page
      return;
    }

    if (hasFetchedRef.current) {
      return;
    }
    hasFetchedRef.current = true;

    setLoading(true);
    axiosInstance
      .get('/get-init-app/')
      .then(response => {
        const data_loaded_for_organization = response.data.data_loaded;
        const user_name = response.data.user_name;
        const user_avatar_url = response.data.user_avatar_url;
        const organization = response.data.organization;
        const data_is_processing = response.data.data_is_processing;
        const historical_data_is_processing =
          response.data.historical_data_is_processing;
        const developers_map_is_processing =
          response.data.developers_map_is_processing;
        const plan = response.data.plan;
        const daysLeftInFreeTrial = response.data.days_left_in_free_trial;
        const loadHistoricalDataPurchase =
          response.data.load_historical_data_purchase;
        const hasPublicRepositories = response.data.has_public_repositories;

        const userData = {
          user_name,
          user_avatar_url,
          organization,
          data_loaded_for_organization,
          data_is_processing,
          developers_map_is_processing,
          plan,
          daysLeftInFreeTrial,
          loadHistoricalDataPurchase,
          hasPublicRepositories
        };

        // Guardamos en localStorage
        localStorage.setItem('userData', JSON.stringify(userData));

        // Mostramos toasts según corresponda
        if (data_is_processing) {
          if (historical_data_is_processing) {
            toast.warning(
              `¡Gracias por tu compra! Estamos procesando tus datos históricos de los últimos meses. Pronto estarán disponibles.`,
              {
                position: 'top-right',
                autoClose: 20000
              }
            );
          } else {
            toast.warning(
              `Se muestran datos de los últimos 7 días. Puede que no veas repos/contribuidores inactivos en ese período. Dispones de 15 días de prueba gratuita.`,
              {
                position: 'top-right',
                autoClose: 20000
              }
            );
          }
        }

        setIsInitData(true);
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  // Scroll al ancla si hay hash en la URL
  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }, 0);
    }
  }, [location.hash]);

  // Scroll arriba al cambiar de ruta
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Mientras esperamos la respuesta, puedes mostrar un spinner.
  // Si prefieres que se vea la interfaz aunque no haya datos, quita este if().
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Cargando datos...</span>
        </Spinner>
      </div>
    );
  }

  // Si quieres que hasta que isInitData sea true no se muestre nada,
  // podrías hacer algo parecido:
  // if (!isInitData) return null;

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <div className={classNames('content', { 'pb-0': isKanban })}>
        <NavbarTop />
        {/* Rutas hijas inyectadas aquí */}
        <Outlet />
        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

export default MainLayout;
