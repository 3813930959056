import useFakeFetch from 'hooks/useFakeFetch';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import PricingDefaultCard from './PricingDefaultCard';

export const pricingData = [
  {
    id: 0,
    name: 'load_data_pack',
    title: 'Historical Data Pack',
    subTitle:
      'Retrieve past GitHub activity with a one-time purchase and extend your historical data as needed.',
    price: 49.9,
    purchaseType: 'oneOff',
    buttonText: 'Unlock Historical Insights',
    isFeatured: true,
    featureTitle: '',
    features: [
      { id: 1, title: 'Unlimited contributors' },
      {
        id: 2,
        title: 'Retrieve up to 6 months of historical data'
      },
      { id: 3, title: 'Commits and Pull Request history' },
      { id: 4, title: 'Investment balance' },
      { id: 5, title: 'Developers radar' },
      { id: 6, title: 'Developers map' },
      { id: 7, title: '1-year data retention' },
      {
        id: 8,
        title:
          'Additional purchases allow you to access even older commits over time.'
      },
      { id: 9, title: 'Smart notifications', blocked: true },
      {
        id: 10,
        title: 'AI weekly and monthly review',
        blocked: true,
        tag: { label: 'New', type: 'success' }
      }
    ]
  },
  {
    id: 2,
    name: 'base_pack',
    title: 'Base Plan',
    subTitle: 'Subscribe to get your new data daily and more funcionalities',
    price: 14.9,
    purchaseType: 'recurring',
    buttonText: 'Start Base Plan',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Unlimited contributors' },
      { id: 2, title: 'Daily data updates' },
      { id: 3, title: 'Commits and Pull Request history' },
      { id: 4, title: 'Investment balance' },
      { id: 5, title: 'Developers radar' },
      { id: 6, title: 'Developers map' },
      { id: 7, title: '1-year data retention' },
      { id: 8, title: 'Share access with other users', blocked: true },
      { id: 9, title: 'Smart notifications', blocked: true },
      {
        id: 10,
        title: 'AI weekly and monthly review',
        blocked: true,
        tag: { label: 'New', type: 'success' }
      }
    ]
  },
  {
    id: 3,
    name: 'advanced_pack',
    title: 'Advanced Plan',
    subTitle: 'Enjoy all the benefits of the Base Plan, plus extra features',
    price: 24.9,
    purchaseType: 'recurring',
    buttonText: 'Start Advanced Plan',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Unlimited contributors' },
      { id: 2, title: 'Daily data updates' },
      { id: 3, title: 'Commits and Pull Request history' },
      { id: 4, title: 'Investment balance' },
      { id: 5, title: 'Developers radar' },
      { id: 6, title: 'Developers map' },
      { id: 7, title: '1-year data retention' },
      { id: 8, title: 'Share access with up to 5 GitHub users' },
      { id: 9, title: 'Smart notifications' },
      {
        id: 10,
        title: 'AI weekly and monthly review',
        tag: { label: 'New', type: 'success' }
      }
    ]
  },
  {
    id: 4,
    name: 'enterprise_pack',
    title: 'Enterprise Plan',
    subTitle:
      'All the perks of the Advanced plan, expanded with more seats for sharing!',
    price: 34.9,
    purchaseType: 'recurring',
    buttonText: 'Start Enterprise Plan',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Unlimited contributors' },
      { id: 2, title: 'Daily data updates' },
      { id: 3, title: 'Commits and Pull Request history' },
      { id: 4, title: 'Investment balance' },
      { id: 5, title: 'Developers radar' },
      { id: 6, title: 'Developers map' },
      { id: 7, title: '1-year data retention' },
      { id: 8, title: 'Share access with up to 10 GitHub users' },
      { id: 9, title: 'Smart notifications' },
      {
        id: 10,
        title: 'AI weekly and monthly review',
        tag: { label: 'New', type: 'success' }
      }
    ]
  }
];

const searchParams = new URLSearchParams(window.location.search);
const plan = searchParams.get('plan');
if (plan === 'out-of-free-trial') {
  toast.warning(
    'Your free trial has ended! Upgrade to a plan now to continue enjoying our services! 😊',
    { position: 'top-right', autoClose: false }
  );
}

const PricingDefault = ({ isPurchasable }) => {
  const { loading: priceLoading, data: pricing } = useFakeFetch(
    pricingData,
    500
  );
  return (
    <>
      {isPurchasable && (
        <WidgetSectionTitle
          icon="credit-card"
          title="Select your plan"
          subtitle="<p><strong>Enjoy a 15-day free trial of our Base Plan!</strong> Your data will be updated daily, and we'll automatically load the last 7 days of your GitHub activity to get you started.</p>
          <p>After the trial, <strong>subscribe to keep your data updated daily</strong> with one of our flexible plans or purchase historical data to explore past activity.</p>
          <p>When you purchase the 'Historical Data Pack', you will retrieve up to 6 months of past GitHub activity (or until 12,000 API credits are used, whichever comes first). 
          You can purchase the 'Historical Data Pack' multiple times to extend your historical data further (each purchase will load data preceding your oldest retrieved commit).</p>"
          transform="shrink-4"
          className="mb-4 mt-2"
        />
      )}
      <Card className="mb-3">
        <Card.Body>
          <Row className="g-0">
            {priceLoading ? (
              <Col xs={12} className="py-4">
                <Spinner
                  className="position-absolute start-50"
                  animation="grow"
                />
              </Col>
            ) : (
              pricing.map(pricing => (
                <PricingDefaultCard
                  key={pricing.id}
                  pricing={pricing}
                  isPurchasable={isPurchasable}
                />
              ))
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

PricingDefault.propTypes = {
  isPurchasable: PropTypes.bool
};
PricingDefault.defaultProps = {
  isPurchasable: true
};
export default PricingDefault;
