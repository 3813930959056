import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

// Create the context
const UserDataContext = createContext();

// Provider that will handle loading and updating userData
export const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  // On mount, load data from localStorage
  useEffect(() => {
    try {
      const storedData = localStorage.getItem('userData');
      if (storedData) {
        setUserData(JSON.parse(storedData));
      }
    } catch (error) {
      console.error('Error retrieving userData from localStorage', error);
    }
  }, []);

  // Function to update userData both in state and localStorage
  const updateUserData = newData => {
    try {
      setUserData(newData);
      localStorage.setItem('userData', JSON.stringify(newData));
    } catch (error) {
      console.error('Error updating userData in localStorage', error);
    }
  };

  // Provide userData and the function to update it
  return (
    <UserDataContext.Provider value={{ userData, setUserData: updateUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};

// Define prop types for UserDataProvider
UserDataProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// Custom hook to easily consume the context
export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }
  return context;
};
