import ComboPieMultiLine from 'components/common/custom-charts/ComboPieMultiLine';
import MultiLineStackHistogramV2 from 'components/common/custom-charts/MultiLineStackHistogram';
import SmallKPIEvolution from 'components/common/custom-charts/SmallKPIEvolution';
import SmallPieChart from 'components/common/custom-charts/SmallPieChart';
import InfoModal from 'components/common/custom-components/InfoModal';
import MainKPI from 'components/dashboards/lms/lms-stats/LmsStats';
import BubbleCloudTags from 'components/dashboards/lms/trending-keywords/TrendingKeywords';
import RankingCommenters from 'components/dashboards/project-management/RankingCommenters';
import RankingCommitters from 'components/dashboards/project-management/RankingCommitters';
import RankingReviewers from 'components/dashboards/project-management/RankingReviewers';
import { DashboardFiltersContext } from 'context/Context';
import { checkIsDataLoaded, EmptyPage } from 'emptyPage';
import axiosInstance from 'http/axiosConfig';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

const Dashboard = () => {
  const [isDataLoaded] = useState(checkIsDataLoaded());
  const [repositoriesCloudTag, setRepositoriesCloudTag] = useState([]);
  const [rankingCommitters, setRankingCommitters] = useState([]);
  const [rankingReviewers, setRankingReviewers] = useState([]);
  const [rankingCommenters, setRankingCommenters] = useState([]);
  const [mainKpis, setMainKpis] = useState([]);
  const [commitsShortEvolution, setCommitsShortEvolution] = useState([]);
  const [prsShortEvolution, setPrsShortEvolution] = useState([]);
  const [commitsByRepo, setCommitsByRepo] = useState([]);
  const [prsByRepo, setPrsByRepo] = useState([]);
  const [performanceHistogram, setPerformanceHistogram] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const filtersContext = useContext(DashboardFiltersContext);
  const [investmentBalanceMultiChart, setInvestmentBalanceMultiChart] =
    useState([]);

  const hasFetchingRef = useRef(false);
  useEffect(() => {
    if (hasFetchingRef.current) {
      return;
    }
    hasFetchingRef.current = true;
    setIsLoading(true);

    axiosInstance
      .get('/get-main-stats/', {})
      .then(response => {
        const data = response.data.dashboard_data;

        setRepositoriesCloudTag(data.cloud_tag);
        setRankingCommitters(data.ranking_committers);
        setRankingReviewers(data.ranking_reviewers);
        setRankingCommenters(data.ranking_commenters);
        setMainKpis(data.mainKpis);
        setCommitsShortEvolution(data.commitsShortEvolution);
        setPrsShortEvolution(data.prsShortEvolution);
        setCommitsByRepo(data.commitsByRepo);
        setPrsByRepo(data.prsByRepo);
        setPerformanceHistogram(data.commits_prs_comments_by_month);
        setInvestmentBalanceMultiChart(data.investment_balance_multichart);

        setIsLoading(false);
        hasFetchingRef.current = false;
      })
      .catch(error => {
        hasFetchingRef.current = false;
        console.error(error);
      });
  }, [filtersContext]);

  return (
    <>
      {isDataLoaded ? (
        <>
          {isLoading ? (
            <Spinner
              className="position-absolute start-50"
              animation="border"
              size="xl"
            />
          ) : (
            <>
              <InfoModal
                dataset={{
                  title: 'General Metrics Summary',
                  description: `This panel displays the four main indicators from the Gitlights history. 
                    It provides a comprehensive view of the total number of <strong>commits</strong>, <strong>pull requests</strong>, 
                    <strong>pull request comments</strong>, and <strong>repositories</strong> loaded. Additionally, it compares these 
                    figures between the entire history and the last month, offering detailed insights 
                    into recent activity.<br />`
                }}
              />
              <MainKPI data={mainKpis} />
              <Row className="g-3 mb-6 main-small-widgets-container">
                <Col md={3} xxl={3}>
                  <InfoModal
                    dataset={{
                      title: 'Monthly Commit Evolution',
                      description: `Get a quick glimpse of the evolution of <strong>commits</strong> over the last three months. 
                        This widget helps understand the development trend and dynamics, providing a 
                        chronological view of activity in Gitlights.<br />`
                    }}
                  />
                  <SmallKPIEvolution dataset={commitsShortEvolution} />
                </Col>
                <Col md={3} xxl={3}>
                  <InfoModal
                    dataset={{
                      title: 'Monthly Pull Request Evolution',
                      description: `Instantly observe how <strong>pull requests</strong> have evolved over the last three months. 
                        This widget provides a chronological overview to understand workflow and collaboration 
                        within the team.<br />`
                    }}
                  />
                  <SmallKPIEvolution dataset={prsShortEvolution} />
                </Col>
                <Col md={3} xxl={3}>
                  <InfoModal
                    dataset={{
                      title: 'Commit Distribution by Repo',
                      description: `Represented in a pie chart, this widget shows the distribution of <strong>commits</strong> grouped 
                        by repository. It offers a visual insight into each project's contribution to the 
                        Gitlights history, highlighting the diversity of activities.<br />`
                    }}
                  />
                  <SmallPieChart
                    dataset={commitsByRepo}
                    radius={['100%', '87%']}
                  />
                </Col>
                <Col md={3} xxl={3}>
                  <InfoModal
                    dataset={{
                      title: 'Pull Request Distribution by Repo',
                      description: `Through a pie chart, visualize the distribution of <strong>pull requests</strong> by repository.
                        This widget allows the identification of participation and collaboration activity 
                        in each project.<br />`
                    }}
                  />
                  <SmallPieChart dataset={prsByRepo} radius={['100%', '87%']} />
                </Col>
              </Row>

              <Row className="g-3 mb-6">
                <Col md={4} xxl={4}>
                  <InfoModal
                    dataset={{
                      title: 'Developer Ranking by Commits',
                      description: `In this table, the ranking of developers is presented based on the number of 
                        <strong>commits</strong> made in the Gitlights history. It highlights individual contributions 
                        and team members' productivity.<br />`
                    }}
                  />
                  <RankingCommitters dataset={rankingCommitters} />
                </Col>
                <Col md={4} xxl={4}>
                  <InfoModal
                    dataset={{
                      title: 'Developer Ranking by Reviews',
                      description: `Displayed in a table, the ranking of developers is shown based on the number of 
                        reviews conducted in <strong>pull requests</strong> throughout the Gitlights history, showcasing 
                        reviewing capacity and collaboration.<br />`
                    }}
                  />
                  <RankingReviewers dataset={rankingReviewers} />
                </Col>
                <Col md={4} xxl={4}>
                  <InfoModal
                    dataset={{
                      title: 'Developer Ranking by Comments',
                      description: `Presented in a table, the ranking of developers is showcased based on the number 
                        of comments made in <strong>pull requests</strong>. It highlights active interaction and participation 
                        in discussions by team members.<br />`
                    }}
                  />
                  <RankingCommenters dataset={rankingCommenters} />
                </Col>
              </Row>
              <Row className="g-3 mb-6">
                <Col md={12} xxl={12}>
                  <InfoModal
                    dataset={{
                      title: 'Temporal Evolution of Activities',
                      description: `This hybrid graph illustrates the temporal evolution of <strong>commits</strong>, pull requests, 
                        and comments. It uses bars for the first two metrics and a line to show the number 
                        of <strong>commits</strong> over the months, providing a comprehensive view of development activity.<br />`
                    }}
                  />
                  <MultiLineStackHistogramV2
                    dataset={performanceHistogram}
                    feature={{
                      dataView: { show: false },
                      magicType: {
                        show: true,
                        type: ['stack', 'tiled']
                      },
                      restore: { show: true },
                      saveAsImage: { show: true }
                    }}
                  />
                </Col>
              </Row>

              <Row className="g-3 mb-6">
                <Col xxl={12}>
                  <InfoModal
                    dataset={{
                      title: 'Investment Balance and Temporal Evolution',
                      description: `This widget offers a detailed analysis of the investment balance in software development, 
      supported by a sophisticated algorithm based on <strong>natural language processing (NLP)</strong> 
      and <strong>artificial intelligence (AI)</strong> technologies. The categorization of commits is done 
      precisely, considering not only the commit message but also other crucial variables, such as lines 
      of code added, deleted, and changes in files.<br /><br />
      
      The algorithm assigns weights to different key areas of development, and the pie chart displays the 
      relative distribution of investments in specific categories. Additionally, the stacked bar chart offers 
      a visual representation of the investment balance over time, revealing the strategic evolution of the 
      project. This advanced process provides insights into the current project focus, spanning from new 
      implementations to maintenance and testing, supporting data-driven decision-making based on key software 
      development variables. An innovative and holistic approach to effective software development investment management.<br /><br />
      
      <strong>Categories:</strong>
      <ul>
        <li><strong>New Development:</strong> Investments in creating and developing new features, algorithms, 
          and significant improvements. Examples include implementing innovative features and creating solutions 
          from scratch. Add the hashtag #feature to the commit message to force the assignment of this category 
          and skip the AI algorithm.</li>
        <li><strong>Refactoring:</strong> Investments aimed at optimizing and improving the structure and efficiency 
          of existing code. This may include code cleanup, performance improvements, and modernization. Add the 
          hashtag #refactor to the commit message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Fixes and Maintenance:</strong> Investments directed at addressing issues and defects, as well 
          as making updates and patches to enhance stability and correct errors. Add the hashtag #fix to the commit 
          message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Testing and QA:</strong> Investments dedicated to ensuring software quality through thorough 
          testing, ensuring correct functionality, and system integrity. Add the hashtag #test to the commit message 
          to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Upgrades:</strong> Investments in the continuous improvement of software through updates, 
          introducing new features, and enhancing the user experience. Add the hashtag #upgrade to the commit 
          message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Security and Compliance:</strong> Investments focused on implementing security measures, 
          addressing vulnerabilities, and complying with standards and regulations. Add the hashtag #security to the 
          commit message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Documentation:</strong> Investments aimed at creating and updating documentation, including README 
          files, comments, and manuals. Add the hashtag #doc to the commit message to force the assignment of this 
          category and skip the AI algorithm.</li>
        <li><strong>Performance Optimization:</strong> Investments to improve system efficiency and speed, including 
          optimization of database queries and algorithm enhancements. Add the hashtag #performance to the commit 
          message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Code Review:</strong> Investments in code review and analysis to ensure the quality and consistency 
          of the source code. Add the hashtag #review to the commit message to force the assignment of this category 
          and skip the AI algorithm.</li>
        <li><strong>Dependency Management:</strong> Investments related to the management and update of dependencies and 
          external libraries. Add the hashtag #dependency to the commit message to force the assignment of this category 
          and skip the AI algorithm.</li>
        <li><strong>CI/CD:</strong> Investments in the implementation and continuous improvement of Continuous Integration 
          (CI) and Continuous Deployment (CD) processes to streamline development and deployment. Add the hashtag #cicd to 
          the commit message to force the assignment of this category and skip the AI algorithm.</li>
      </ul><br />
      The presented data belongs to the subset of filters applied in the header.<br />`
                    }}
                  />

                  <ComboPieMultiLine dataset={investmentBalanceMultiChart} />
                </Col>
              </Row>

              <Row className="g-3 mb-6">
                <Col xxl={12}>
                  <InfoModal
                    dataset={{
                      title: 'Repository Term Cloud',
                      description: `Using a symbolic term cloud representation, this widget visualizes each repository's 
                        contribution to the organization's total lines of code. The cloud highlights the 
                        relative importance of each project based on the font size, providing a visual snapshot
                         of the codebase's structure and magnitude.<br />`
                    }}
                  />
                  <BubbleCloudTags data={repositoriesCloudTag} />
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <EmptyPage />
        </>
      )}
    </>
  );
};

export default Dashboard;
