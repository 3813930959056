// Base variable to define the public subdomain prefix
const PUBLIC_SUBDOMAIN_PREFIX = 'public.gitlights.com';
//const PUBLIC_SUBDOMAIN_PREFIX = 'localhost';

// Utility to check if the current hostname uses the public subdomain
const isPublicDomain = () => {
  const hostname = window.location.hostname;
  return hostname.startsWith(PUBLIC_SUBDOMAIN_PREFIX);
};

// Utility to extract the organization from the current pathname
// Returns undefined if the domain is not public or the org is not found
const getOrgFromPath = () => {
  if (!isPublicDomain()) {
    return undefined;
  }

  const pathname = window.location.pathname;

  // Primero, se intenta capturar la org en rutas que incluyen "/dashboard/"
  const regexDashboard = /^\/([^/]+)\/dashboard\/([^/]+)\/?$/;
  const matchDashboard = pathname.match(regexDashboard);

  if (matchDashboard) {
    // matchDashboard[1] es la org
    return matchDashboard[1];
  }

  // Si no coincide con el patrón de dashboard, se prueba el caso "/org" (e.g. localhost:3000/django)
  const regexOrgOnly = /^\/([^/]+)\/?$/;
  const matchOrgOnly = pathname.match(regexOrgOnly);

  if (matchOrgOnly) {
    return matchOrgOnly[1];
  }

  return undefined;
};

export { isPublicDomain, getOrgFromPath };
