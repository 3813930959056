import React, {
  forwardRef,
  useState,
  useContext,
  useCallback,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardFiltersContext } from 'context/Context';

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <div className="position-relative">
    <Form.Control
      size="sm"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={e => {
        console.log({ e });
      }}
    />
    <FontAwesomeIcon
      icon="calendar-alt"
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

DateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

const FilterDateInput = () => {
  const { setFilters } = useContext(DashboardFiltersContext);

  // Fecha por defecto: 90 días atrás
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 90);

  // Estados locales para startDate y endDate
  const [startDate, setStartDate] = useState(
    localStorage.getItem('filterStartDate')
      ? new Date(localStorage.getItem('filterStartDate'))
      : defaultStartDate
  );
  const [endDate, setEndDate] = useState(
    localStorage.getItem('filterEndDate')
      ? new Date(localStorage.getItem('filterEndDate'))
      : new Date()
  );

  // Creamos una referencia para controlar la apertura/cierre del DatePicker
  const datePickerRef = useRef(null);

  // Función auxiliar para formatear la fecha (dd/mm/yyyy)
  const formatDate = useCallback(date => {
    return (
      date.getDate() +
      '/' +
      parseInt(date.getMonth() + 1) +
      '/' +
      date.getFullYear()
    );
  }, []);

  // Maneja el cambio de fechas desde el DatePicker
  const onChange = dates => {
    const [start, end] = dates || [];
    setStartDate(start);
    setEndDate(end);

    // Si tenemos ambas fechas, actualizamos store y localStorage
    if (start && end) {
      localStorage.setItem('filterStartDate', start);
      localStorage.setItem('filterEndDate', end);

      setFilters({
        id: 50,
        startDate: formatDate(start),
        endDate: formatDate(end)
      });
    }
  };

  // Función que maneja la selección de atajos
  const handleShortcut = shortcut => {
    let newStart = new Date();
    let newEnd = new Date();

    switch (shortcut) {
      case 'last7':
        newStart.setDate(newEnd.getDate() - 7);
        break;
      case 'lastMonth':
        newStart.setMonth(newEnd.getMonth() - 1);
        break;
      case 'lastYear':
        newStart.setMonth(newEnd.getMonth() - 12);
        break;
      case 'yearToDate':
        newStart = new Date(newEnd.getFullYear(), 0, 1);
        break;
      default:
        break;
    }

    // Usamos la misma lógica de onChange para actualizar estados y contexto
    onChange([newStart, newEnd]);

    // Cerramos el DatePicker manualmente
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(false);
    }
  };

  // Contenedor personalizado (calendarContainer) con los atajos
  const CalendarContainer = ({ className, children }) => {
    return (
      <div
        className={className}
        style={{ backgroundColor: 'var(--falcon-body-bg)' }}
      >
        {/* Atajos en el pop-up */}
        <div className="d-flex justify-content-around p-2 date-pciker-quickactions-container">
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => handleShortcut('lastYear')}
          >
            Last Year
          </Button>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => handleShortcut('yearToDate')}
          >
            Year to date
          </Button>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => handleShortcut('lastMonth')}
          >
            Last Month
          </Button>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => handleShortcut('last7')}
          >
            Last 7 days
          </Button>
        </div>
        {/* Renderizado del calendario */}
        {children}
      </div>
    );
  };

  CalendarContainer.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
  };

  return (
    <DatePicker
      ref={datePickerRef}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      monthsShown={2}
      dateFormat="MMM dd"
      formatWeekDay={day => day.slice(0, 3)}
      customInput={<DateInput />}
      calendarContainer={CalendarContainer}
      shouldCloseOnSelect={false}
    />
  );
};

export default FilterDateInput;
