import AdvanceSearchTable from 'components/common/custom-charts/AdvanceSearchTable';
import Quadrant from 'components/common/custom-charts/Quadrant';
import InfoModal from 'components/common/custom-components/InfoModal';
import {
  DashboardFilterDevelopersContext,
  DashboardFilterRepositoriesContext,
  DashboardFiltersContext
} from 'context/Context';
import { checkIsDataLoaded, EmptyPage } from 'emptyPage';
import axiosInstance from 'http/axiosConfig';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

const DevelopersView = () => {
  const [isDataLoaded] = useState(checkIsDataLoaded());
  const [isLoading, setIsLoading] = useState(true);
  const [globalInfoDevTable, setGlobalInfoDevTable] = useState([]);
  const [scatterChartDevelopersMap, setScatterChartDevelopersMap] = useState(
    []
  );

  const filtersContext = useContext(DashboardFiltersContext);
  const developersFiltersContext = useContext(DashboardFilterDevelopersContext);
  const repositoriesFiltersContext = useContext(
    DashboardFilterRepositoriesContext
  );

  const hasFetchingRef = useRef(false);
  useEffect(() => {
    if (hasFetchingRef.current) {
      return;
    }
    hasFetchingRef.current = true;
    setIsLoading(true);

    axiosInstance
      .get('/get-global-developers-dashboard/', {
        params: {
          startDate: filtersContext.filters.startDate,
          endDate: filtersContext.filters.endDate,
          developers:
            developersFiltersContext.developersFilter.developers.toString(),
          repositories:
            repositoriesFiltersContext.repositoriesFilter.repositories.toString()
        }
      })
      .then(response => {
        const data = response.data.dashboard_data;
        setIsLoading(false);
        setGlobalInfoDevTable(data.global_info_dev_table);
        setScatterChartDevelopersMap(data.scatter_chart_developers_map);
        hasFetchingRef.current = false;

        var storedUserData = localStorage.getItem('userData');
        var parsedUserData = JSON.parse(storedUserData);
        if (parsedUserData.developers_map_is_processing) {
          toast.warning(
            `Our advanced developer map is currently applying a complex scoring 
              allocation algorithm. It will be available in the next few hours.
               In the meantime, we appreciate your patience.`,
            { position: 'top-right', autoClose: 20000 }
          );
        }
      })
      .catch(error => {
        hasFetchingRef.current = false;
        console.error(error);
      });
  }, [filtersContext, developersFiltersContext, repositoriesFiltersContext]);

  return (
    <>
      {isDataLoaded ? (
        <>
          {isLoading ? (
            <Spinner
              className="position-absolute start-50"
              animation="border"
              size="xl"
            />
          ) : (
            <>
              <Row className="g-3 mb-6">
                <Col md={12} xxl={12}>
                  <InfoModal
                    dataset={{
                      title: 'Scatter Chart: Developer Contribution Profile',
                      description: `
                        <p>This scatter chart provides a detailed map of the organization's developers, positioning them in four quadrants based on their individual contribution (Y-axis) and collective contribution (X-axis).</p>

                        <h5>Individual Contribution (Y-Axis):</h5>
                        <p>Individual contribution is calculated through an algorithm that considers various factors such as lines added, commit frequency, and those marked by the AI system as new developments or fixes. It encompasses any action representing an individual developer's contribution that benefits the organization overall, even if it doesn't have a direct impact on the rest of the development team in terms of direct professional improvement or easing their work.</p>

                        <h5>Collective Contribution (X-Axis):</h5>
                        <p>Collective contribution is determined through an algorithm that takes into account comments on pull requests, the number of initiated conversations, the count of commits marked as refactors, cicd, among others. It also considers aspects like commit message size and the quantity of lines deleted, along with other reference variables. These variables provide a deeper understanding of whether the developer, beyond their individual actions, contributes to the collective, either by aiding professional growth or facilitating the lives of the rest of the development team with clearer, commented, and scalable code.</p>

                        <h5>Quadrants of the Scatter Chart:</h5>
                        <ul>
                          <li><strong>High Individual and Collective Contribution:</strong> Developers in this quadrant excel both individually and collectively, acting as leaders and making a significant impact on the team and the organization.</li>
                          <li><strong>High Individual Contribution and Low Collective Contribution:</strong> Developers who strongly contribute individually but whose collective contribution may be more limited. They may stand out for their technical excellence.</li>
                          <li><strong>Low Individual Contribution and High Collective Contribution:</strong> Developers who may not stand out individually but have a significant impact collectively, either by facilitating collaboration or improving code quality.</li>
                          <li><strong>Low Individual and Collective Contribution:</strong> Developers whose individual and collective contributions may require more attention. They may benefit from increased involvement or professional development.</li>
                        </ul>

                        <p>This map is essential for understanding the overall landscape of profiles in the team and how each one contributes to the organization, providing a valuable tool for resource management and optimization.</p>
                      `
                    }}
                  />

                  <Quadrant
                    dataset={scatterChartDevelopersMap}
                    showLegend={false}
                  />
                </Col>
              </Row>
              <Row className="g-3 mb-6">
                <Col md={12} xxl={12}>
                  <InfoModal
                    dataset={{
                      title: "Developers' Indicators Table",
                      description: `<p>This table presents a conglomerate of commit and pull request data 
                      for each developer. It is primarily useful for obtaining a summary of key indicators 
                      for each developer. You can search for a specific developer and click to navigate to 
                      the detailed developer page.</p>`
                    }}
                  />
                  <AdvanceSearchTable
                    dataset={globalInfoDevTable}
                    perPage={20}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <EmptyPage />
        </>
      )}
    </>
  );
};

export default DevelopersView;
